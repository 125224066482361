import React from 'react';
import styled from 'styled-components';
import Colors from '../constants/Colors';

const ProjectCardContainer = styled.a`
  flex: 0 45%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${Colors.green};
  border-radius: 5px;
  margin: 0 4px;
  transition: background 0.3s;
  &:hover {
    background: black;
  }
`;

export default function ProjectCard(props) {
  const { name, url } = props.project;
  return (
    <ProjectCardContainer href={url}>
      <p>{name}</p>
    </ProjectCardContainer>
  );
}
