import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProjectCard from '../components/ProjectCard';
import Colors from '../constants/Colors';

const ProjectsContainer = styled.div`
  display: flex;
`;

const ProjectPage = () => {
  const projects = [
    { name: 'taberdashery' },
    { name: 'kinshape' },
    { name: 'hockeycompass', url: 'https://hockeycompass.com/' },
    { name: 'funes the memorious' },
  ];
  return (
    <Layout>
      <SEO title="projects" />
      <h1>stuff we've made</h1>
      <ProjectsContainer>
        {projects.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </ProjectsContainer>
      <AniLink direction="up" cover bg={Colors.green} to="/">
        home
      </AniLink>
    </Layout>
  );
};

export default ProjectPage;
